.imprecoDataTable {
  align-items: center;
  padding: 10px;
  & > div {
    padding: 5px;
  }
}

.imprecoButtonRow {
  display: flex;
  margin: 25px 0;
  flex-direction: row;
  flex-wrap: wrap;
  & > * {
    box-sizing: border-box;
    margin-left: 0;
  }
}
.imprecoLogoRow {
  color: white;
  background: linear-gradient(
    -90deg,
    rgba(76, 166, 193, 1) 0%,
    rgba(38, 83, 97, 1) 70%
  );
  display: grid;
  grid-template-columns: 360px auto;
  align-items: center;
  justify-content: center;
  position: relative;
  & > a {
    width: 300px;
    height: 200px;
    align-self: center;
    justify-self: center;
  }
}
.imprecoParagraph {
  text-align: justify;
}

.imprecoSocialMediaContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
  display: flex;
  flex-direction: row;
  & > a > img {
    height: 2rem;
    margin: 0.3rem;
  }
}
