.languageFlag {
    width: 30px;
}
.languageButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border: 2px;
    height: 100%;
    width: 7rem;
    background: rgb(150, 150, 150);
    color: white;
    box-sizing: border-box;
    padding: 5px;
    font-size: 0.85rem;
    z-index: 400;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        background: rgb(200, 200, 200);
    }
    & > img {
        padding-right: 0.3rem;
    }
    @media only screen and (max-width: 1179px) {
        font-size: 0.6rem;
    }
}
.languagePicker {
    position: relative;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 401;
    display: flex;
    flex-direction: column;
    & > .languageSelectionBackground {
        position: absolute;
        top: 100%;
        z-index: 401;
        padding-top: 5px;
    }
}
@media only screen and (max-width: 1179px) {
    .languageButton {
        width: 6rem;
    }
}

.languageIcon {
    height: 1.7rem;
    box-sizing: border-box;
    color: white;
}
