@import './Components/Pages/Impreco/Impreco.scss';
@import './Components/_commonStyles.scss';
@import './Components/NewNavBar2/NavBar.scss';
@import './Components/NewNavBar2/Components/DropDown/DropDown.scss';
@import './Components/ToolBar/ToolBar.scss';
@import './Components/LanguagePicker/languageButtons.scss';
@import './Components/Common/InvertedColorsButton/InvertedColorsButton.scss';

.imprecoDataTable {
    display: grid;
    grid-template-columns: 200px auto;
    align-items: center;
    padding: 10px;
    & > div {
        padding: 5px;
    }
}

.imprecoButtonRow {
    display: flex;
    margin: 25px 0;
    flex-direction: row;
    flex-wrap: wrap;
    & > * {
        box-sizing: border-box;
        margin-left: 0;
    }
}
.imprecoLogoRow {
    color: white;
    background: linear-gradient(-90deg, rgba(76, 166, 193, 1) 0%, rgba(38, 83, 97, 1) 70%);
    display: grid;
    grid-template-columns: 360px auto;
    align-items: center;
    justify-content: center;
    & > a {
        width: 300px;
        height: 200px;
        align-self: center;
        justify-self: center;
    }
}
