$navBarHeight: 5.5rem;
.navLogo {
    height: 4.5rem;
    width: 4.5rem;
    margin-left: 0.5rem;
    transition: height 0.5s, width 0.5s, margin-left 0.5s;
    cursor: pointer;
}
.dropDownButton {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 10px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(100, 100, 100, 0.9);
    text-decoration: none;
    & > i {
        margin-left: 10px;
    }
    &:hover {
        background: rgba(170, 170, 170, 0.9);
    }
}
.dropDown {
    max-width: 300px;
    min-width: 180px;
    transition: height 0.5s;
}
.navBar {
    z-index: 111;
    background-color: #144250bb;
    height: $navBarHeight;
    width: calc(100% - 6rem);
    display: flex;
    flex-direction: row;
    position: fixed;
    align-items: center;
    top: $toolBarHeight + 1rem;
    margin: 0 3rem 1rem 3rem;
    transition: margin 0.5s, height 0.5s, width 0.5s, top 0.5s;
    & > a {
        height: 100%;
        display: flex;
        align-items: center;
    }
    & > .imprecoContainer {
        position: absolute;
        top: 6rem;
        right: 0;
        opacity: 1;
        transition: height 0.5s, width 0.5s, opacity 0.5s;
        & > img {
            cursor: pointer;
        }
    }
    & > .hamburgerButton {
        display: none;
        cursor: pointer;
    }
    & > .dropDown {
        flex-direction: row;
        background: none;
        margin: 0;
        height: 100%;
        max-width: unset;
        min-width: unset;
        z-index: 111;
        & > .dropDownContainer {
            margin: 0;
            z-index: 111;
            & > .ddchild {
                left: 0;
                padding-top: 10px;
                top: 100%;
            }
            & > .dropDownButton {
                color: white;
                height: 100%;
                background: unset;
                padding: 10px 20px;
                font-weight: 700;
                margin: 0;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                text-align: center;
                cursor: pointer;
                &.active {
                    background: rgba(255, 255, 255, 0.3);
                }
                &:hover {
                    background: rgba(255, 255, 255, 0.3);
                }
            }
        }
    }
}
.sticky {
    height: $navBarHeight * 0.6;
    width: 100%;
    top: $toolBarHeight;
    margin: 0;
    transition: margin 0.5s, height 0.5s, width 0.5s, top 0.5s;
    & > a > .navLogo {
        height: 4.5rem * 0.6;
        width: 4.5rem * 0.6;
        margin-left: 0.5rem;
        cursor: pointer;
    }
    & > .imprecoContainer {
        opacity: 0;
    }
}

@media only screen and (max-width: 1179px) {
    .dropDownContainer {
        background: rgb(100, 100, 100);
    }
    .dropDownButton {
        background-color: unset !important;
        text-align: center;
        :active {
            background-color: unset !important;
        }
        :hover {
            background-color: unset !important;
        }
    }
    .navBar {
        height: 0.6 * $navBarHeight;
        width: 85vw;
        width: 100vw;
        margin: 0;
        top: $toolBarHeight;
        & > a > .navLogo {
            height: 0.5 * $navBarHeight !important;
            width: 0.5 * $navBarHeight;
        }
        & > .imprecoContainer {
            position: absolute;
            top: 0.6 * $navBarHeight;
            margin-top: 1rem;
            width: 100vw;
            display: flex;
            align-items: center;
            height: unset;
            justify-content: center;
            & > img {
                max-width: 70vw;
                position: relative;
                top: 0;
                cursor: pointer;
            }
        }
        background: #144250;
        & > * {
            font-size: 1.35rem;
        }
        justify-content: space-between;
        & > .hamburgerButton {
            display: inline-block;
            padding: 1rem;
            height: 100%;
        }
        & > .dropDown {
            max-height: 80vh;
            position: absolute;
            height: 0;
            overflow: hidden;
            overflow-y: scroll;
            top: 0.6 * $navBarHeight;
            flex-direction: column;
            background-color: #1f8fb2 !important;
            width: 100%;
            & > .dropDownContainer {
                background: #1f8fb2;
                width: 100%;
                margin: 0;
                & > .dropDown {
                    position: static;
                    max-width: unset;
                    left: unset;
                    top: unset;
                    display: flex;
                    padding: 0;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    & > .dropDownContainer {
                        width: 100%;
                        margin: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        & > .dropDownButton {
                            width: auto;
                            margin: 0;
                        }
                        & > .dropDown {
                            position: static;
                            max-width: unset;
                            left: unset;
                            top: unset;
                            display: flex;
                            padding: 0;
                            flex-direction: column;
                            align-items: center;
                            width: 100%;
                            & > .dropDownContainer {
                                width: 100%;
                                margin: 0;
                                display: flex;
                                background-color: rgb(130, 130, 130);
                                flex-direction: column;
                                align-items: center;

                                & > .dropDownButton {
                                    width: auto;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        & > .expanded {
            height: auto;
        }
    }
}
